
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  AppRoutingModule
} from './app-routing.module';
// import { TranslateModule } from '@ngx-translate/core';
import {
  AppComponent
} from './app.component';
import {
  ApiserviceService
} from './apiservice.service';
import {
  AuthGuard
} from './auth.guard';
import {
  AuthService
} from './auth.service';
import {
  SharedModule
} from './shared/shared.module'
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  MatSnackBarModule
} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {  MatDialogModule } from '@angular/material/dialog';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { StreamChatModule, StreamAutocompleteTextareaModule } from 'stream-chat-angular';
import { VideoRecordingService } from './video-recording.service';
import {CarouselModule} from 'primeng/carousel';
import {ButtonModule} from 'primeng/button';

import { StreamChatModule, StreamAutocompleteTextareaModule } from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ChatScreenComponent } from './chat-screen/chat-screen.component';
import { GroupChatComponent } from './group-chat/group-chat.component';
import { DialougeComponent } from './dialouge/dialouge.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {ImageModule} from 'primeng/image';
import { InviteButtonComponent } from './invite-button/invite-button.component';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    FooterComponent,
    ChatScreenComponent,
    GroupChatComponent,
    DialougeComponent,
    InviteButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    NgbModule,
    CarouselModule,
    ButtonModule,
    TranslateModule.forRoot(), StreamAutocompleteTextareaModule, StreamChatModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ImageModule
  ],
  
  providers: [
    ApiserviceService,
    AuthService,
    AuthGuard,
    VideoRecordingService
  ],
  
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 50]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        /* setTimeout is the core line to solve the solution */
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
