import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { ChatScreenComponent } from './chat-screen/chat-screen.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { GroupChatComponent } from './group-chat/group-chat.component';

const routes: Routes = [
 
  { 
    path: 'dashboard', 
    loadChildren: () => import(`./dashboard/dashboard.module`).then(
      module => module.DashboardModule
    )
  },
  { 
    path: '', 
    loadChildren: () => import(`./auth/auth.module`).then(
      module => module.AuthModule
    )
  },
  {
    path: "chat",
    canActivate: [AuthGuard],
    component: ChatScreenComponent,
  },
  {
    path: "chat-group",
    canActivate: [AuthGuard],
    component: GroupChatComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Restore the last scroll position
    // scrollPositionRestoration: "enabled",
    // scrollOffset: [0, 0],
    // Enable scrolling to anchors
    // anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
