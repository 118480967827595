import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ChannelService, ChatClientService, StreamI18nService } from 'stream-chat-angular';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class StreamService {
	private streamReady = new BehaviorSubject(true);
	user: any;
	userGuid: any;

	constructor(
		private authService: AuthService,
		private chatService: ChatClientService,
		private channelService: ChannelService,
		private streamI18nService: StreamI18nService
	) {
		this.user = JSON.parse(localStorage.getItem('data'))
		console.log(this.user)
		const user = this.user
		const guid = this.user.data.guid
		const getstream_token = this.user.data.getstream_token
		const client_user_id = JSON.stringify(this.user.data.client_user_id)
		// this.authService.currentUser.subscribe((user: any) => {
        //     console.log(user)

		// this.userGuid = user.guid

		// 	if (user && user.getstream_token) {
		// 		this.chatService.init(environment.streamKey, `${user.guid}`, user.getstream_token);
		// 		this.streamI18nService.setTranslation();
		// 		this.initChat();
		// 	} else {
				
		// 			this.chatService.disconnectUser();
					
		// 			this.channelService.reset();
				

		// 		this.streamReady.next(false);
		// 	}
		// });
		
            
			if (user && getstream_token) {
				console.log(user, getstream_token)
				this.chatService.init(environment.streamKey, this.user.data.user_guid, getstream_token);
				
				this.streamI18nService.setTranslation();
				// this.initChat();
			} else {
				console.log(this.chatService.chatClient)
				if (this.chatService.chatClient) {
					this.chatService.disconnectUser();
					this.channelService.reset();
				  }

				// this.streamReady.next(true);
			}
		
	}
	
	async initChat() {
		
			await this.channelService.init({
				type: 'messaging',
				members: { $in: [this.user.data.user_guid]},
			});
			this.streamReady.next(true);
		
		
		
	}

	isStreamReady() {
		return this.streamReady.asObservable();
	}
}