import { Component, OnInit } from '@angular/core';
import { StreamService } from 'src/app/stream.service';
import { ChatClientService, ChannelService, StreamI18nService, StreamMessage } from 'stream-chat-angular';

import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
  NavigationEnd
} from '@angular/router';
import { ApiserviceService } from '../apiservice.service';
import { AuthService } from '../auth.service';
import {MatDialog} from '@angular/material/dialog';

import { DialougeComponent } from '../dialouge/dialouge.component';



@Component({
  selector: 'app-group-chat',
  templateUrl: './group-chat.component.html',
  styleUrls: ['./group-chat.component.scss']
})
export class GroupChatComponent implements OnInit {

  // chatReady = this.streamService.isStreamReady();
  btnColor = environment.BtnColor
  Color = environment.Color
  border = environment.border

  avatar: any;
  userId: string;
  filter: any;
  filterNew: any;
  newGet: string;
  URL: string;
  user: any;
  isMenuOpen = true;
  countUnreadArr: any = [];
  unreadCount: any;
  unreadCount2: any;
  pinnedMessage:any
  loading: boolean;
  newArray: any = [];
  sum: any;
  chnMembers: any;
  chnanelId: any = [];
  membrShip: string;
  load: boolean = true;
  memberList: any = [];
  selectImg: any;
  
  constructor(private apiService: ApiserviceService, private actiRoute: ActivatedRoute, public router: Router, private _snackbar: MatSnackBar, private autService: AuthService,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private streamService: StreamService,
    public dialog: MatDialog,
  ) {
    this.user = JSON.parse(localStorage.getItem('data'))
    const apiKey = environment.streamKey;
    const userId = this.user.data.guid;
    const userTok = this.user.data.getstream_token;
    this.chatService.init(apiKey, userId, userTok);

//     const channel = this.channelService.channels('messaging', 'test');
// await channel.watch();
    
    this.streamI18nService.setTranslation();
   
  }

 async ngOnInit() {
    this.URL = localStorage.getItem('URL')
    this.channelService.reset()
    // this.getChat()
    if(this.URL.includes('chat-group')){
    await  this.channelService.init({
        type: 'messaging',
        members: { $in: [this.user.data.guid]},
        member_count:{$gte: 3}
      });
    }

    this.chatService.chatClient.queryUsers({ id: { $exists: true } }).then(res=>{
      this.memberList = res.users;
      console.log(res)
      
      })

    this.channelService?.channels$.subscribe(res=>{
      
      res?.forEach(res=>{
      
       this.countUnreadArr.push(res?.countUnread())
       
      })
      this.newArray = this.countUnreadArr

      this.sum = this.newArray.reduce((acc, cur) => acc + cur, 0);
       localStorage.setItem('chatgrcount', this.sum)
      // console.log(res)
      // console.log(this.countUnreadArr.reduce((a, b) => a + b, 0))
      // this.unreadCount = this.countUnreadArr.reduce((a, b) => a + b, 0)
      // let returnLarger = (arr, num) => arr.filter(n => n > num);
      // console.log(returnLarger( this.countUnreadArr, 0))
      // this.unreadCount2 = returnLarger( this.countUnreadArr, 0)
    })

    this.channelService.activeChannelPinnedMessages$.subscribe(res=>{
      this.pinnedMessage = res
      console.log(this.pinnedMessage)
    });
    
     this.channelService.activeChannel$.subscribe(res=>{
      res?.cid
      
        this.chnanelId = res?.cid.split(':');
        console.log(this.chnanelId)
    })

this.channelService.activeChannel$.subscribe(res=>{
  res?.watch().then(res=>{
    console.log(res.members)
    this.chnMembers = res?.members;
      this.membrShip = res?.membership.role 
  })
})
    await this.channelService.activeChannel$.forEach(res=>{
      this.load=true;
    
      res?.watch().then(res=>{
      this.load=false;
        // let chnlarry = res?.members;
    this.chnMembers = res?.members;
      this.membrShip = res.membership.role 
    
      },
      err=>{
        this.load=true;
        this.channelService.activeChannel$.forEach(res=>{
          // console.log(res)
          
          res?.watch().then(res=>{
            // let chnlarry = res?.members;
            this.load=false;
        this.chnMembers = res?.members;
          this.membrShip = res.membership.role
        console.log(this.membrShip)
        
        
          })
        })
      })
    })

    
    
    this.autService.profilePic.subscribe(data => {
      if(data == ''){
        this.avatar = localStorage.getItem('avatar')
        if(this.avatar === 'null'){
          this.avatar = ''
        }
        console.log(this.avatar)
      }else{
        this.avatar = data
      }

      console.log(data)
    })



    

    this.userId = localStorage.getItem("id");
   this.URL = localStorage.getItem('URL')
   console.log(this.URL)

    if(this.URL.includes('search-')){
    this.filter = localStorage.getItem('searchData')
    }else{
    localStorage.removeItem('searchData')
    this.filter = ''
    }
    
    this.autService.searchData.subscribe(data =>{
      this.filter = data
    })
  }

  // addModer(data:any){
  //   let x = [];
  //   x.push(data)
  //   // this.channelService.channels$.forEach(res=>{
  //   //   res.forEach(res=>{
  //   //     console.log(res.state.)
  //   //   })
  //   // })

    
  // }

  addModer(data: any) {
    
      this.apiService.addModerator(this.chnanelId[1], data).subscribe(
        (response: any) => {
         console.log(response)
          
        }, (error: any) => {
         console.log(error)
        }
      );
    
  }

  removeModer(data: any) {
    
    this.apiService.removeModerator(this.chnanelId[1], data).subscribe(
      (response: any) => {
       console.log(response)
        
      }, (error: any) => {
       console.log(error)
      }
    );
  
}

removeMemb(data:any){
//  return this.channelService.activeChannel$.forEach(res=>{
//     console.log(res)
//   })
// this.chatService.chatClient.
}

async oncreate(event:any, data:any, grpName:any){
  var arr: any = [];
  arr = event

  arr.push(this.user.data.guid);
  console.log(arr)

//     var arr: any = [];
//     arr.push(event.value);
//     const name = JSON.parse(arr[0]).name;
// console.log(JSON.parse(arr[0]).guid)


// const dasherizedName = (JSON.parse(arr[0]).name).replace(/\s+/g, '-').toLowerCase();
const channel = this.chatService.chatClient.channel(
  'messaging', 
   {
    members: arr,
    image: data,
    name:grpName
});

await channel.watch();

  }
 

  jumpToMessage(message: StreamMessage) {
    this.channelService.jumpToMessage(message.id, message.parent_id);
  }
  unpinMessage(message: StreamMessage) {
    this.channelService.unpinMessage(message);
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "320px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  
  logout() {
    this.apiService.userLogout().subscribe(
      (res:any)=>{
        this._snackbar.open(res.message, "Thanks", {
          duration: 3000
        });
        localStorage.clear()
        this.chatService.disconnectUser();
					this.channelService.reset();
        this.router.navigate(['/'])
      },
      (err:any)=>{
        this._snackbar.open("Something Went Wrong", "Try Again", {
          duration: 3000
        });
      }

    );
    
  
  }

  getChat(){
    this.loading =true
    if(this.URL.includes('chat-group')){
      this.loading = false
      this.channelService.init({
        type: 'messaging',
        members: { $in: [this.user.data.guid]},
        member_count:{$gte: 3}
      });
    }
  }

  charCount(data:any) {
    localStorage.setItem("searchData",this.filter)
   this.newGet = localStorage.getItem("searchData")
    this.autService.searchData.next(this.newGet)
    if(this.URL.includes('search-contact') || this.URL.includes('/dashboard/directory')){
      this.router.navigate(['/dashboard/search-contact', this.filter])
    }else{
      this.router.navigate(['/dashboard/search-post', this.filter])
    }
  }

  openDialog(text) {
    const dialogRef = this.dialog.open(DialougeComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn'],
      disableClose: true,
      autoFocus: false,
      width: '800px',
      data: {
        pinned: this.pinnedMessage,
        members: this.chnMembers,
        userId: this.user.data.guid,
        userList: this.memberList,
        membrShip: this.membrShip,
        text: text,
        yes: 'Yes',
        no: 'OK',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result !== undefined) {
        if(result.text == 'jump'){
          this.jumpToMessage(result.data)
        }
        if(result.text == 'unpin'){
        this.unpinMessage(result.data)
        }
        if(result.text == 'add'){
          this.addModer(result.data)
          }
          if(result.text == 'remove'){
            this.removeModer(result.data)
            }
            if(result.text == 'delete'){
              this.removeMemb(result.data)
              }

              if(result.text == 'gropChat'){
                console.log(result)
                this.oncreate(result.data, result.file, result.grpName)
                }

      }
    });
  }

}
