<div class="modal-content" *ngIf="selectedList.text=='pin'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i> Pinned Message</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body " *ngIf="selectedList.pinned.length > 0">
      <div class="row" >
        
            <div class="col-md-3"
            style="padding: 8px; background: #e1f5fe; cursor: pointer; margin: 1em; word-break: break-all !important;"
            *ngFor="let message of selectedList.pinned" (click)="jumpToMessage(message, 'jump')">
            {{ message.text }}
            <span *ngIf="!message.text">
              <div *ngFor="let media of message.attachments">
                <img *ngIf="media.type== 'image'" src="{{media.image_url}}" style="width: 50px; height:50px">
               <span *ngIf="media.type== 'file'" style="word-break: break-all !important;"> <i class="fa fa-file-alt"></i> {{media.title}}</span> 
                <span *ngIf="media.type== 'giphy'"><img src="{{media.thumb_url}}" style="width: 50px; height:50px"> </span>
               <span *ngIf="media.type== 'video'"> <video src="{{media.asset_url}}"  style="width: 200px; height:150px"></video> </span> <!-- <video src=""></video> -->
              </div>
            </span>
           
            <button
    style="border: none; background: none; display: block;"
    (click)="$event.stopPropagation(); unpinMessage(message, 'unpin')"
    title="unpin"
  >
  <i class="fas fa-thumbtack"></i>
  </button>
  

          
          </div>
      </div>
    </div>
    <div class="modal-body " *ngIf="selectedList.pinned.length == 0">
        <div class="row" >
            <p>No Message Available</p>
          
            
        </div>
      </div>
   
  </div>

  <div class="modal-content" *ngIf="selectedList.text == 'memb'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i> Members</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body ">
      <div class="row" >
        <div class="col-md-12 " *ngFor="let member of selectedList.members">
          <div class="row">
            <div class="col-2 col-md-2 p-3">
              <img src="{{member.user.image}}" *ngIf="member.user.image" alt="" class="img-fluid mem-img">
              <i class="fas fa-user" *ngIf="!member.user.image"></i>
            </div>
            <div class="col-5 col-md-5 p-3">{{member.user.name}}</div>
            <div class="col-5 col-md-5 p-3">
              <button class="btn post-btn" (click)="changeRouteProf(member?.user?.guid, 'prof')">View Profile</button>
            </div>
            <!-- <div class="col-3 col-md-3 p-3">
              <button class="btn post-btn" *ngIf="member?.role != 'moderator'" (click)="addModer(member?.user?.guid, 'add')">Add Moderator</button>
            </div> -->
            
          </div>
        </div>
      </div>
      </div>
  </div>

  <div class="modal-content" *ngIf="selectedList.text == 'grpMemb'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i> Members</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body ">
      <div class="row" >
        <div class="col-md-12 " *ngFor="let member of selectedList.members">
          <div class="row">
            <div class="col-2 col-md-2 p-3">
              <img src="{{member.user.image}}" *ngIf="member.user.image" alt="" class="img-fluid mem-img">
              <i class="fas fa-user" *ngIf="!member.user.image"></i>
            </div>
            <div class="col-4 col-md-4 p-3">{{member.user.name}}</div>
            <div class="col-3 col-md-3 p-3">
              <button class="btn post-btn" (click)="changeRouteProf(member?.user?.guid, 'prof')">View Profile</button>
            </div>
            <div class="col-3 col-md-3 p-3" *ngIf="selectedList.membrShip == 'owner' || selectedList.membrShip == 'moderator'">
              <button class="btn post-btn" *ngIf="member?.role != 'moderator' && member?.user?.guid != selectedList.userId" (click)="addModer(member?.user?.guid, 'add')">Add Moderator</button>
              <button class="btn post-btn" *ngIf="member?.role == 'moderator' && member?.user?.guid != selectedList.userId" (click)="addModer(member?.user?.guid, 'remove')">Remove Moderator</button>
              <!-- <button class="btn post-btn" *ngIf="member?.role == 'member' && member?.user?.guid != selectedList.userId" (click)="addModer(member?.user?.guid, 'delete')">Remove member</button> -->
            
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>

  <div class="modal-content" *ngIf="selectedList.text == 'attach'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i>Images & Video</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body text-center" *ngIf="selectedList.attach.length > 0">

          
            <span *ngFor="let attach of selectedList.attach" >
              <span *ngFor="let att of attach.attachments">
              <span *ngIf="att.type== 'image'" class="imgs">
                <!-- <img src="{{att.image_url}}" (click)="jumpToMessage(attach, 'jump')" style="width: 100px; height:100px; display: inline-block; padding: 1em; cursor: pointer;">   -->
                <p-image src="{{att.image_url}}" alt="Image" width="100" [preview]="true" ></p-image><br> <span (click)="jumpToMessage(attach, 'jump')"><i class="fa fa-eye" style="cursor: pointer;" ></i></span> 

              </span>  
                <span *ngIf="att.type== 'video'" style="display: inline-block; padding: 1em;"> <video src="{{att.asset_url}}" controls style="width: 200px; height:150px"></video> <br> <span (click)="jumpToMessage(attach, 'jump')"><i class="fa fa-eye" style="cursor: pointer;" ></i> </span> </span>
              
              </span>
                
            </span>
            
         
            
      </div>
      
      <div class="modal-body text-center" *ngIf="selectedList.attach.length == 0">
        No Images and Video Shared
      </div>
  </div>
  <div class="modal-content" *ngIf="selectedList.text == 'attachfile'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i>Files Shared</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body text-center" *ngIf="selectedList.attachFile.length > 0">

          <span *ngFor=" let attach of selectedList?.attachFile; let idx = index; " (click)="jumpToMessage(attach, 'jump')">
            
         <span *ngFor="let at of attach.attachments">
          <span  style="display: inline-block; padding: 1em; cursor: pointer;">  <i class="fa fa-file-alt" style="width: 30px; height:30px" ></i> {{at.title}} <a href="{{at.asset_url}}" download> <i class="fa fa-download"  aria-hidden="true"></i> </a></span>
          
        </span>
        </span>
            
      </div>
      <div class="modal-body text-center" *ngIf="selectedList.attachFile.length == 0">
        No Files Shared
      </div>
  </div>

  <div class="modal-content" *ngIf="selectedList.text == 'chat'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i>New Chat</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body text-center" >

          <span class="chatList">
            <ul class="p-0 ">
              <li *ngFor=" let attach of selectedList?.userList; let idx = index; "
              style="list-style-type: none; " (click)="newChat(attach, 'chat')">

                <span *ngIf="!attach.name" >  {{attach.id}}</span>
               <span>{{attach.name}}</span> 
              </li>
              
            </ul>
            
        </span>
            
      </div>
     
  </div>
  <div class="modal-content" *ngIf="selectedList.text == 'grpChat'">
    <h4 class="modal-title"><i class="fa fa-info-circle"></i>Create Group Chat</h4><span (click)="onNoClick()"
      data-dismiss="modal" style="padding: .75em 1em; color: #fff; cursor: pointer; position: absolute;
      right: 0;"><i class="fa fa-times close-times"></i> </span>
  
    <div class="modal-body text-center" >

          <span class="chatList" *ngIf="imgUp">
            <ul class="p-0 ">
              <li *ngFor=" let attach of selectedList?.userList; let idx = index; "
              style="list-style-type: none; " >

                <span *ngIf="!attach.name" >  {{attach.id}}</span>
               <span>{{attach.name}}</span> 
               <input type="checkbox" (change)="onChange(i,attach.id, $event.target.checked)" >
              </li>
              
            </ul>
            
        </span>

        <div *ngIf="!imgUp" style="flex-wrap: wrap;
        flex-direction: column;">
          <div>
            <label for="Grp">Group Image: </label>
            <input type="file" name="Grp" (change)="onImageSelect($event)" accept="image/*" #file>
          </div>
          <div>
            <label for="name">Group Name: </label>
            <input type="text" name="name" [(ngModel)]="grpName" (change)="grpNameS($event)" >
          </div>

          
        </div>
            
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" *ngIf="!imgUp" [disabled]="loadBtn" (click)="contGrpCht()">Continue</button>
        <button class="btn btn-primary" *ngIf="imgUp" (click)="newGrpChat('gropChat')">Create Group</button>
      </div>
     
  </div>