import { Component, OnInit, Input } from '@angular/core';
import { Injectable, NgZone } from '@angular/core';

import { StreamService } from 'src/app/stream.service';
import { ChatClientService, ChannelService, StreamI18nService, StreamMessage, ChannelActionsContext, AttachmentListContext, AttachmentListComponent, DefaultStreamChatGenerics, } from 'stream-chat-angular';

import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  Router,
  NavigationEnd
} from '@angular/router';
import { ApiserviceService } from '../apiservice.service';
import { AuthService } from '../auth.service';
import {MatDialog} from '@angular/material/dialog';

import { DialougeComponent } from '../dialouge/dialouge.component';
import { Channel } from 'stream-chat';
import { Action, Attachment } from 'stream-chat';

@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrls: ['./chat-screen.component.scss']
})

export class ChatScreenComponent implements OnInit {

  // chatReady = this.streamService.isStreamReady();
  btnColor = environment.BtnColor
  Color = environment.Color
  border = environment.border

  avatar: any;
  userId: string;
  filter: any;
  filterNew: any;
  newGet: string;
  URL: string;
  user: any;
  isMenuOpen = true;
  countUnreadArr: any = [];
  unreadCount: any;
  unreadCount2: any;
  pinnedMessage:any
  newArray: any = [];
  sum: any;
  openModal: boolean;
  muteStatus: any;
  chnMembers: any;
  otherMeb: any;
  @Input() message: StreamMessage;
  hasAttachment: boolean;
  attach: boolean;
  public channel: Channel;
  attchArray: any = [];
  // chnMembers: { chanId: any;};
  @Input() attachments: Attachment<DefaultStreamChatGenerics>[] = [];
  attchArrayFile: any = [];
  attachData: any;
  activeChannel: Channel | undefined;
  filteredMovies: any = [];
  isLoading: boolean;
  memberList: any = [];
  newMemList: any= [];
  arrUniq: any = [];
  load: boolean = true;
  constructor(private apiService: ApiserviceService, private actiRoute: ActivatedRoute, public router: Router, private _snackbar: MatSnackBar, private autService: AuthService,
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private streamService: StreamService,
    public dialog: MatDialog,
    private ngZone: NgZone,
   
  ) {
    this.user = JSON.parse(localStorage.getItem('data'))
    const apiKey = environment.streamKey;
    const userId = this.user.data.guid;
    const userToken = this.user.data.getstream_token;
    this.chatService.init(apiKey, userId, userToken);
    
    this.streamI18nService.setTranslation();
    this.channelService.activeChannel$.subscribe(
      (c) => (this.activeChannel = c)
    );

    this.channelService.reset()
   
  }

  ngOnInit() {
    this.channelService.reset()
    this.URL = localStorage.getItem('URL')
    if(this.URL.includes('chat')){
    this.channelService.init({
      type: 'messaging',
      members: { $in: [this.user.data.guid]},
    });
  }
  
  this.channelService?.channels$.subscribe(res=>{
      
    res?.forEach(res=>{
   
     this.countUnreadArr.push(res?.countUnread())
      
    })
    // console.log(res)

    this.newArray = this.countUnreadArr
    this.sum = this.newArray.reduce((acc, cur) => acc + cur, 0);
    localStorage.setItem('chatcount', this.sum)
  })
   
  // this.channelService.activeChannel$.subscribe(res=>{
  //   console.log(res?.muteStatus())
  //   this.muteStatus = res?.muteStatus().muted
  //   console.log(res?.state.read)
  // })

  this.channelService.activeChannel$.subscribe(res=>{
    // res?.forEach(res=>{
      
    // })
    this.muteStatus= res?.muteStatus()
      // console.log(this.muteStatus)
  })

//   this.channelService.activeChannel$.subscribe(res=>{
//     // res
   
//     console.log(res?.state?.members)
//     this.chnMembers = {
//       chanId: res?.state?.members,
//     }
// // console.log(this.chnMembers?.chanId)
    

//   })

 this.channelService.channels$.subscribe(res=>{
  // console.log(res)
  res?.forEach(res=>{
    // console.log(res.countUnread())
    let x = res.countUnread();
    if(x){
      this.unreadCount = x;
    localStorage.setItem('chatcount', this.unreadCount)
    this.autService.chatCount.next(this.unreadCount)
    }
    
  })
 })

 
 

    this.channelService.activeChannelPinnedMessages$.subscribe(res=>{
      this.pinnedMessage = res
      // console.log(this.pinnedMessage)
    });

    this.channelService.activeChannel$.forEach(res=>{
      this.load = true;
      res?.watch().then(res=>{
      this.load = false;
        this.chnMembers = res?.members;
        this.attchArray = [];
        this.attchArrayFile = [];
        res?.messages.forEach(res=>{
          
          if(res?.attachments.length > 0){
            // console.log(res?.id)
            // console.log(res)
            this.attachData = res
            res?.attachments.forEach(res=>{
              if(res.type == 'file'){
                // console.log('file', res.id)
                this.attchArrayFile.push(this.attachData)
                // console.log(this.attchArrayFile)
              }else{
                this.attchArray.push(this.attachData)
              }
            })
            
            // console.log(this.attchArray)
          }
          
        })
      },
      err => {
        this.channelService.activeChannel$.forEach(res=>{
          
          res?.watch().then(res=>{
            this.load= false;
            this.chnMembers = res?.members;
            this.attchArray = [];
            this.attchArrayFile = [];
            res?.messages.forEach(res=>{
              
              if(res?.attachments.length > 0){
                // console.log(res?.id)
                // console.log(res)
                this.attachData = res
                res?.attachments.forEach(res=>{
                  if(res.type == 'file'){
                    // console.log('file', res.id)
                    this.attchArrayFile.push(this.attachData)
                    // console.log(this.attchArrayFile)
                  }else{
                    this.attchArray.push(this.attachData)
                  }
                })
                
                // console.log(this.attchArray)
              }
              
            })
          })
        })
      })
    })

// this.channelService.activeChannel$.forEach(res=>{

//   res?.watch().then(res=>{
// this.chnMembers = res?.members;
//   },
//   err =>{
//     this.channelService.activeChannel$.forEach(res=>{
//       res?.watch().then(res=>{
//     this.chnMembers = res?.members;
//       })
//     })
//   })
// })

// returns up to 100 members ordered by created_at descending
this.chatService.chatClient.queryUsers({ id: { $exists: true } }).then(res=>{
this.memberList = res.users;
console.log(res)

})


    
    this.autService.profilePic.subscribe(data => {
      if(data == ''){
        this.avatar = localStorage.getItem('avatar')
        if(this.avatar === 'null'){
          this.avatar = ''
        }
        // console.log(this.avatar)
      }else{
        this.avatar = data
      }

      // console.log(data)
    })



    

    this.userId = localStorage.getItem("id");
   this.URL = localStorage.getItem('URL')
  //  console.log(this.URL)

    if(this.URL.includes('search-')){
    this.filter = localStorage.getItem('searchData')
    }else{
    localStorage.removeItem('searchData')
    this.filter = ''
    }
    
    this.autService.searchData.subscribe(data =>{
      this.filter = data
    })

    
  }

  // async initChat() {
		
	// 		await this.channelService.init({
	// 			type: 'messaging',
	// 			members: { $in: [this.user.data.user_guid]},
  //       member_count: {$gte: 2}
	// 		});
	// 		// this.streamReady.next(true);
		
		
		
	// }

  jumpToMessage(message: StreamMessage) {
    this.channelService.jumpToMessage(message.id, message.parent_id);
  }

  jumpToMessage1(message: StreamMessage) {
    this.channelService.jumpToMessage(message.cid);
  }

  unpinMessage(message: StreamMessage) {
    this.channelService.unpinMessage(message);
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "320px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  
  logout() {
    this.apiService.userLogout().subscribe(
      (res:any)=>{
        this._snackbar.open(res.message, "Thanks", {
          duration: 3000
        });
        localStorage.clear()
        this.chatService.disconnectUser();
					this.channelService.reset();
        this.router.navigate(['/'])
      },
      (err:any)=>{
        this._snackbar.open("Something Went Wrong", "Try Again", {
          duration: 3000
        });
      }

    );
    
  
  }

async oncreate(event:any){
  console.log(event.id)
//     var arr: any = [];
//     arr.push(event.value);
//     const name = JSON.parse(arr[0]).name;
// console.log(JSON.parse(arr[0]).guid)


// const dasherizedName = (JSON.parse(arr[0]).name).replace(/\s+/g, '-').toLowerCase();
const channel = this.chatService.chatClient.channel(
  'messaging', 
   {
    members: [this.user.data.guid, event.id]
});
await channel.watch();
  }

  viewAttch(){
    this.attach = true
  }

  clrMsg(){
   this.channelService.activeChannel$.subscribe(res=>{
    res.delete()
   })
  }

  openDialog(data:any) {
    const dialogRef = this.dialog.open(DialougeComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn'],
      disableClose: true,
      autoFocus: false,
      width: '800px',
      data: {
        pinned: this.pinnedMessage,
        members: this.chnMembers,
        userList: this.memberList,
        attach: this.attchArray,
        attachFile: this.attchArrayFile,
        text: data,
        yes: 'Yes',
        no: 'OK',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result)
      if (result !== undefined) {
        if(result.text == 'jump'){
          this.jumpToMessage(result.data)
        }
        if(result.text == 'unpin'){
        this.unpinMessage(result.data)
        }
        if(result.text == 'chat'){
          this.oncreate(result.data)
          }
        if(result.text == 'prof'){
          this.router.navigate(['/dashboard/direct-prof', result.data]);
        }
      }
    });
  }

  charCount(data:any) {
    localStorage.setItem("searchData",this.filter)
   this.newGet = localStorage.getItem("searchData")
    this.autService.searchData.next(this.newGet)
    if(this.URL.includes('search-contact') || this.URL.includes('/dashboard/directory')){
      this.router.navigate(['/dashboard/search-contact', this.filter])
    }else{
      this.router.navigate(['/dashboard/search-post', this.filter])
    }

//   this.isLoading = true;
// if(data.length > 0){
//   this.channelService.channels$.forEach(res=>{
//     this.isLoading = false;
//     res.forEach(res=>{
//       res.search(data).then(res=>{
//         if(res.results.length > 0){
//           // console.log(res.results)
//           res.results.forEach(res=>{
//             // console.log(res)
//             this.filteredMovies.push(res)
//             console.log(this.filteredMovies)

//           })
          
//         }
        
        
//       })
//       // console.log(res.search(data))
//     })
//   })
// }
// else{
//   this.filteredMovies = [];
// }
  // this.channelService.channels$.forEach(res=>{
  //   this.isLoading = false;
  //   res.forEach(res=>{
  //     res.search(data).then(res=>{
  //       console.log(res.results)
  
  //       this.filteredMovies = res.results
  //     })
  //     // console.log(res.search(data))
  //   })
  // })

  }

  displayWith(value: any) {
    return value;
  }

  muteChk(event:any){
// console.log(event.target.checked)
if(event.target.checked == true){
 this.channelService.activeChannel$.forEach(res=>{
  res.mute()
 })
 
  
}else{
  this.channelService.activeChannel$.subscribe(res=>{
    res.unmute()
  })

}

// if(event.target.checked == true){
//   // this.channelService.activeChannel$.subscribe(res=>{
//   //   res.
//   //  })

 

 
// }else{
//   this.channelService.activeChannel$.subscribe(res=>{
//     console.log(res.state._channel.unmute())
//    return res.state._channel.unmute()
//    })
// }
  }
}
