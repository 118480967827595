
export const environment = {
  production: false,
  apiUrl: "https://stg.omgiva.com/api",
  streamKey: "9835gyj6ph75",
  Color:  "0",
  BtnColor:  "#76A4DF",
  border:  "1px solid #76A4D",
  tokenXAuth: "acs8oDkPKGTvGrz7Y2Kz80cljEFiNxOXsRF2Cc0QuvU94jetLPSTUkq5D1VUfc4a"
};
