import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StreamService } from 'src/app/stream.service';
import { ChatClientService, ChannelService, StreamI18nService, StreamMessage } from 'stream-chat-angular';
import { ApiserviceService } from '../apiservice.service';
@Component({
  selector: 'app-dialouge',
  templateUrl: './dialouge.component.html',
  styleUrls: ['./dialouge.component.scss']
})
export class DialougeComponent implements OnInit {
  selectedList: any;
  view: boolean = false;
  id: any;
  checkedMem: any = [];
  imgUp: boolean;
  file: any;
  result: string;
  grpImage: any;
  grpName: any;
  loadBtn: boolean;

  constructor(private apiService: ApiserviceService, public dialogRef: MatDialogRef < DialougeComponent >,private router: ActivatedRoute,  private routes: Router, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedList = this.data;
    console.log(this.selectedList)
   }

  ngOnInit(): void {
    this.id = this.router.snapshot.params['id'];
  }

  onNoClick(): void {
    
    this.dialogRef.close();
  }

  changeRouteProf(id:any, text:any){
    this.dialogRef.close({
      data: id,
      text: text,
    });
  }

  addModer(id:any, text:any){
    this.dialogRef.close({
      data: id,
      text: text,
    });
  }

  newChat(id:any, text:any){
    this.dialogRef.close({
      data: id,
      text: text,
    });
  }

  onChange(index: number, data:{_id:string,name:string}, isChecked: boolean) {
    if (isChecked) {
      // console.log(data)
      this.checkedMem.push(data)
      console.log(this.checkedMem)

    } else {
      this.checkedMem.splice(this.checkedMem.indexOf(data),1);
      console.log(this.checkedMem)

      // this.editCategoryForm.controls.categoryArray.value[index].measurements.splice(this.editCategoryForm.controls.categoryArray.value[index].measurements.indexOf(data),1);
    }
}

newGrpChat(text:any){
  this.dialogRef.close({
    data: this.checkedMem,
    file: this.grpImage,
    grpName: this.grpName,
    text: text,
  });
}

contGrpCht(){
  this.imgUp = true
}

randomString(length:any) {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  this.result = '';
  for ( var i = 0; i < length; i++ ) {
      this.result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return this.result;
}

onImageSelect(event:any){
  this.loadBtn = true;
  console.log(event.target.value)
  this.file = event.target.files[0]

  this.randomString(20);
  console.log(this.result)
  const formData = new FormData();
     
      formData.append('image',this.file)
      
this.apiService.groupImage(formData, this.result).subscribe(
  (response: any) => {
    this.loadBtn = false
   this.grpImage= response.data.image;
   console.log(this.grpImage)

    
  }, (error: any) => {
   console.log(error)
  }
);
//   const reader = new FileReader();
// const [file] = event.target.files;

// reader.readAsDataURL(file);
 
//   reader.onload = () => {

//     this.file = reader.result as string;
//     console.log(this.file)

//   };

console.log(this.file)
}

grpNameS(event:any){
this.grpName=event.target.value
console.log(this.grpName)
}

  jumpToMessage(message:any, text:any) {
    // this.channelService.jumpToMessage(message.id, message.parent_id);
console.log('this')
    this.dialogRef.close({
      data: message,
      text: text
    });

  }

  unpinMessage(message:any, text:any){
    this.dialogRef.close({
      data: message,
      text: text

    });
  }

}
