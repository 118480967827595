<div class="container header-container mob p-0">
    <div class="row">
      <div class="col-12 flexible-header">
        <div class="first-sec">
          <!-- <span class="logo mb-5 mob"> <img src="assets/logo.png" class="img-fluid" /> OMGIVA</span>
                 -->
          <!-- <button>Menu</button> -->
          <div class="topnav">
            <!-- <a href="#home" class="active">Logo</a> -->
            <a href="javascript:void(0);" class="icon" (click)="openNav()">
  
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </a>
            <div id="mySidenav" class="sidenav">
              <div class="row">
                <div class="col-4">
                  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                </div>
                <div class="col-8 closebtnimg">
                  <a  routerLink="/" class="logo-anchor"> <img src="assets/logo.png" class="img-fluid " /> <span
                      class="omgiva-text" [style.color]="btnColor"> OMGIVA</span></a>
                </div>
              </div>
              <a  routerLink="/dashboard/home/main" routerLinkActive="active" (click)="closeNav()"> <span class="span" routerLinkActive="active" #rla="routerLinkActive">
                <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="26.131" height="21.575" viewBox="0 0 26.131 21.575">
                  <path id="Shape_15" data-name="Shape 15" d="M74,168.224v-19.6a1,1,0,0,1,1.052-.616q12.04.014,24.078,0a.973.973,0,0,1,1,.616v19.6a1.869,1.869,0,0,1-2.089,1.331c-3.814-.03-7.628-.012-11.443-.012H75.731a1.653,1.653,0,0,1-1.623-1.043Zm7.35-2.461h3.6a.818.818,0,0,0,.893-.883V155.4a.819.819,0,0,0-.906-.894H77.764a.819.819,0,0,0-.9.894v9.453a.823.823,0,0,0,.921.908Zm5.512-13.692H96.05a.8.8,0,0,0,.815-.829.815.815,0,0,0-.829-.789h-18.3a.826.826,0,0,0-.826.526.817.817,0,0,0,.839,1.1q4.555-.007,9.111-.007Zm5.715,4.06h3.441a.811.811,0,0,0,.831-.973.83.83,0,0,0-.883-.648H89.187a1.185,1.185,0,0,0-.278.025.809.809,0,0,0,.226,1.6C90.283,156.136,91.429,156.133,92.576,156.132Zm-.039,3.249h3.445a.812.812,0,1,0-.037-1.62H89.183a.821.821,0,1,0,.011,1.623Zm-.011,3.247h3.42a.813.813,0,1,0,.011-1.622H89.144a.815.815,0,1,0,.016,1.622Q90.842,162.631,92.526,162.628Zm7.6-14.006v19.6m-7.6-2.417h3.42a.813.813,0,1,0,.011-1.622H89.144a.815.815,0,1,0,.016,1.622q1.682.008,3.367.005Z" transform="translate(-74 -148.004)"  [ngStyle]="rla.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                </svg>
              </span><span>Home</span> <i
                  class="fa fa-angle-right"></i> </a>
              <a  routerLink="/chat"  routerLinkActive="active" (click)="closeNav()"> <span class="span" routerLinkActive="active" #rlam="routerLinkActive"> 
                  
                <svg xmlns="http://www.w3.org/2000/svg" class="tab-img" width="24.197" height="24.196" viewBox="0 0 24.197 24.196">
                  <path id="Shape_10" data-name="Shape 10" d="M283.274,172.2a1.269,1.269,0,0,1-.791-.327l-3.209-3.207a.949.949,0,0,0-.566-.235h-9.077a.557.557,0,0,1-.557-.557V161.6a.239.239,0,0,0-.239-.239h-6.272a.557.557,0,0,1-.557-.557v-12.24a.557.557,0,0,1,.557-.557h16.013a.557.557,0,0,1,.557.557v6.274a.239.239,0,0,0,.239.239h6.274a.557.557,0,0,1,.557.557v12.243a.557.557,0,0,1-.557.557h-1.562a.239.239,0,0,0-.239.239v2.973a.557.557,0,0,1-.557.557ZM263.82,149.584a.239.239,0,0,0-.239.239v9.729a.239.239,0,0,0,.239.239h5.016a.239.239,0,0,0,.239-.239v-3.917a.557.557,0,0,1,.557-.557h7.688a.239.239,0,0,0,.239-.239v-5.016a.239.239,0,0,0-.239-.239Z" transform="translate(-262.007 -148.008)" [ngStyle]="rlam.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}" />
                </svg>
  
                </span><span>Chat</span> <i
                  class="fa fa-angle-right "></i> {{unreadCount}}</a>
              <a routerLink="/dashboard/directory"  routerLinkActive="active" (click)="closeNav()"> <span class="span" routerLinkActive="active" #rlad="routerLinkActive"> 
                  
                <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="24.213" height="24.817" viewBox="0 0 24.213 24.817">
                  <path id="tab_icon_contact" d="M471.885,172.812H453.213a1.446,1.446,0,0,1-1.549-1.549v-4.245h-.857a.826.826,0,1,1,0-1.652h.857v-2.493h-.857a.826.826,0,0,1,0-1.652h.857v-2.463H450.8a.839.839,0,0,1,0-1.676h.866v-2.47h-.86a.826.826,0,0,1,0-1.652h.86v-3.37A1.515,1.515,0,0,1,453.249,148h18.637a2.315,2.315,0,0,1,2.3,2.326v20.16A2.314,2.314,0,0,1,471.885,172.812Zm-2.667-7.678a2.08,2.08,0,0,0-1.126-1.756c-.191-.1-.382-.207-.581-.3-.346-.15-1.815-.77-1.815-.77a.81.81,0,0,1-.528-.739,4.56,4.56,0,0,1,.017-.62,1.067,1.067,0,0,1,.254-.606,1.469,1.469,0,0,0,.322-.671c.021-.109.035-.221.05-.332s.029-.228.052-.342a.373.373,0,0,1,.1-.184,1.168,1.168,0,0,0,.356-.587,4.983,4.983,0,0,0,.089-.534c.039-.329-.032-.462-.31-.543,0-.1-.006-.2-.008-.3a5.728,5.728,0,0,0-.053-.773,2.05,2.05,0,0,0-1.508-1.772,2.98,2.98,0,0,0-.861-.123,3.843,3.843,0,0,0-.542.04,2.1,2.1,0,0,0-1.816,1.5,3.782,3.782,0,0,0-.131,1.3l-.025.089a.587.587,0,0,0-.294.577,1.3,1.3,0,0,0,.349,1,.7.7,0,0,1,.227.524,1.92,1.92,0,0,0,.413,1.167,1.178,1.178,0,0,1,.2.386,2.039,2.039,0,0,1,.021.981.744.744,0,0,1-.5.556l-.612.266c-.441.191-.883.382-1.322.581a3.1,3.1,0,0,0-1,.62,2.291,2.291,0,0,0-.577,1.872.321.321,0,0,0,.292.3,2.1,2.1,0,0,0,.31.026h9.953a2.315,2.315,0,0,0,.259-.015.345.345,0,0,0,.358-.382,3.667,3.667,0,0,0-.011-.441Z" transform="translate(-449.977 -147.998)" [ngStyle]="rlad.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                </svg>
  
              </span><span>Directory</span>
                <i class="fa fa-angle-right "></i></a>
              <a  routerLink="/chat-group"  routerLinkActive="active " (click)="closeNav()"> <span class="span" routerLinkActive="active" #rlag="routerLinkActive"> 
                  
                <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="24.714" height="20.798" viewBox="0 0 24.714 20.798">
                  <g id="multiple-users-silhouette" transform="translate(0 -6.348)">
                    <path id="Path_1132" data-name="Path 1132" d="M14.914,9.918A4.491,4.491,0,0,1,17,13.254a3.623,3.623,0,1,0-2.09-3.336Zm-2.375,7.421a3.624,3.624,0,1,0-3.625-3.624A3.625,3.625,0,0,0,12.539,17.339Zm1.538.247H11A4.646,4.646,0,0,0,6.36,22.227v3.761l.01.059.259.081a21.125,21.125,0,0,0,6.31,1.018,12.954,12.954,0,0,0,5.51-1.034l.242-.122h.026V22.227A4.645,4.645,0,0,0,14.077,17.586Zm6-3.742H17.022a4.467,4.467,0,0,1-1.379,3.111,5.515,5.515,0,0,1,3.939,5.277v1.159a12.489,12.489,0,0,0,4.864-1.022l.242-.123h.026V18.485A4.646,4.646,0,0,0,20.074,13.844ZM6.179,13.6a3.6,3.6,0,0,0,1.928-.559,4.486,4.486,0,0,1,1.686-2.861c0-.068.01-.135.01-.2A3.624,3.624,0,1,0,6.179,13.6Zm3.255,3.358a4.47,4.47,0,0,1-1.378-3.094c-.113-.008-.225-.017-.341-.017H4.641A4.646,4.646,0,0,0,0,18.485v3.762l.01.058.259.082a21.759,21.759,0,0,0,5.226.982V22.233A5.516,5.516,0,0,1,9.435,16.956Z" [ngStyle]="rlag.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                  </g>
                </svg>
  
                </span><span>Group</span> <i
                  class="fa fa-angle-right "></i></a>
              <hr>
              <a routerLink="/dashboard/notification"  routerLinkActive="active" (click)="closeNav()"> <span>Notification Setting</span> <i class="fa fa-angle-right"></i>
              </a>
              <a routerLink="/dashboard/about"  routerLinkActive="active" (click)="closeNav()"> <span>About</span><i class="fa fa-angle-right"></i> </a>
              <a routerLink="/dashboard/terms"  routerLinkActive="active" (click)="closeNav()"> <span>Terms of Service</span><i class="fa fa-angle-right "></i>
              </a>
              <a (click)="logout()"> <span>Logout</span> <i class="fa fa-angle-right "></i></a>
            </div>
  
          </div>
        </div>
        <div class="tab-icons-holder second-sec">
          <a  routerLink="/" class="logo-anchor-1"> <span class="mob-logo-home"> <img src="assets/logo.png"
                class="img-fluid" /> <span class="omgiva-text" [style.color]="btnColor"> OMGIVA </span> </span></a>
  
        </div>
        <div class="third-sec">
          <!-- <div class="search-div">
                    <input class="form-control search-input" placeholder="search"> <i class="fa fa-search"></i>
                </div> -->
          <div>
            <div class="dropdown">
              <button class="dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img class="pro-icon" src={{avatar}}> </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" routerLink="/dashboard/home/profile">Edit Profile</a></li>
                <li><a class="dropdown-item" routerLink="/dashboard/notification">Notification Setting</a></li>
                <li><a class="dropdown-item" routerLink="/dashboard/about">About </a></li>
                <li><a class="dropdown-item" routerLink="/dashboard/terms">Terms of Service </a></li>
                <li (click)="logout()"><a   class="dropdown-item">Logout</a></li>
              </ul>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container header-container web">
    <div class="row ">
      <div class="col-12 flexible-header">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-xl-4 col-sm-3 col-3">
            <div class="first-sec">
              <span class="logo web logo-home-pos"> <a  routerLink="/" class="logo-anchor"> <img src="assets/logo.png"
                    class="img-fluid" /> <span class="omgiva-text" [style.color]="btnColor"> OMGIVA </span> </a></span>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-xl-4 col-sm-5 col-5">
            <div class="tab-icons-holder second-sec">
              <a routerLink="/dashboard/home/main"  routerLinkActive="active"  > 
                <!-- <img src="assets/icon_home - Copy.svg" class="tab-img" [ngStyle]="{'color': 'red'}" /> -->
                <span class="span" routerLinkActive="active" #rla="routerLinkActive">
                  <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="26.131" height="21.575" viewBox="0 0 26.131 21.575">
                    <path id="Shape_15" data-name="Shape 15" d="M74,168.224v-19.6a1,1,0,0,1,1.052-.616q12.04.014,24.078,0a.973.973,0,0,1,1,.616v19.6a1.869,1.869,0,0,1-2.089,1.331c-3.814-.03-7.628-.012-11.443-.012H75.731a1.653,1.653,0,0,1-1.623-1.043Zm7.35-2.461h3.6a.818.818,0,0,0,.893-.883V155.4a.819.819,0,0,0-.906-.894H77.764a.819.819,0,0,0-.9.894v9.453a.823.823,0,0,0,.921.908Zm5.512-13.692H96.05a.8.8,0,0,0,.815-.829.815.815,0,0,0-.829-.789h-18.3a.826.826,0,0,0-.826.526.817.817,0,0,0,.839,1.1q4.555-.007,9.111-.007Zm5.715,4.06h3.441a.811.811,0,0,0,.831-.973.83.83,0,0,0-.883-.648H89.187a1.185,1.185,0,0,0-.278.025.809.809,0,0,0,.226,1.6C90.283,156.136,91.429,156.133,92.576,156.132Zm-.039,3.249h3.445a.812.812,0,1,0-.037-1.62H89.183a.821.821,0,1,0,.011,1.623Zm-.011,3.247h3.42a.813.813,0,1,0,.011-1.622H89.144a.815.815,0,1,0,.016,1.622Q90.842,162.631,92.526,162.628Zm7.6-14.006v19.6m-7.6-2.417h3.42a.813.813,0,1,0,.011-1.622H89.144a.815.815,0,1,0,.016,1.622q1.682.008,3.367.005Z" transform="translate(-74 -148.004)"  [ngStyle]="rla.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                  </svg>
                </span>
                
                <!-- <span class="span" routerLinkActive="active" #rla="routerLinkActive"> 
                  <img src="{{ rla.isActive ? 'assets/icon_home.svg' : 'assets/icon_home - Copy.svg'}}" class="tab-img" /> 
                  </span> -->
                <h1 class="border" [ngStyle]="rla ? {'background-color': btnColor} : {}"></h1>
              </a>
              <a routerLink="/chat" routerLinkActive="active" style="position: relative;"> 
                <!-- <img src="assets/icon_message.svg" class="tab-img" />  -->
                <!-- <span style="position: absolute;
                right: 0;
                background: red;
                color: #fff;
                padding: 2px 5px;
                border-radius: 50%;
                top: -13px;
                ">{{unreadCount}}</span> -->
                <span class="span" routerLinkActive="active" #rlam="routerLinkActive"> 
                  
                  <svg xmlns="http://www.w3.org/2000/svg" class="tab-img" width="24.197" height="24.196" viewBox="0 0 24.197 24.196">
                    <path id="Shape_10" data-name="Shape 10" d="M283.274,172.2a1.269,1.269,0,0,1-.791-.327l-3.209-3.207a.949.949,0,0,0-.566-.235h-9.077a.557.557,0,0,1-.557-.557V161.6a.239.239,0,0,0-.239-.239h-6.272a.557.557,0,0,1-.557-.557v-12.24a.557.557,0,0,1,.557-.557h16.013a.557.557,0,0,1,.557.557v6.274a.239.239,0,0,0,.239.239h6.274a.557.557,0,0,1,.557.557v12.243a.557.557,0,0,1-.557.557h-1.562a.239.239,0,0,0-.239.239v2.973a.557.557,0,0,1-.557.557ZM263.82,149.584a.239.239,0,0,0-.239.239v9.729a.239.239,0,0,0,.239.239h5.016a.239.239,0,0,0,.239-.239v-3.917a.557.557,0,0,1,.557-.557h7.688a.239.239,0,0,0,.239-.239v-5.016a.239.239,0,0,0-.239-.239Z" transform="translate(-262.007 -148.008)" [ngStyle]="rlam.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}" />
                  </svg>
                  
                  </span>
                  
                <h1 class="border" [ngStyle]="rlam ? {'background-color': btnColor} : {}"></h1></a>
              <a routerLink="/dashboard/directory"  routerLinkActive="active"  > 
                <!-- <img src="assets/icon_contact.svg" class="tab-img" /> -->
                <span class="span" routerLinkActive="active" #rlad="routerLinkActive"> 
                  
                    <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="24.213" height="24.817" viewBox="0 0 24.213 24.817">
                      <path id="tab_icon_contact" d="M471.885,172.812H453.213a1.446,1.446,0,0,1-1.549-1.549v-4.245h-.857a.826.826,0,1,1,0-1.652h.857v-2.493h-.857a.826.826,0,0,1,0-1.652h.857v-2.463H450.8a.839.839,0,0,1,0-1.676h.866v-2.47h-.86a.826.826,0,0,1,0-1.652h.86v-3.37A1.515,1.515,0,0,1,453.249,148h18.637a2.315,2.315,0,0,1,2.3,2.326v20.16A2.314,2.314,0,0,1,471.885,172.812Zm-2.667-7.678a2.08,2.08,0,0,0-1.126-1.756c-.191-.1-.382-.207-.581-.3-.346-.15-1.815-.77-1.815-.77a.81.81,0,0,1-.528-.739,4.56,4.56,0,0,1,.017-.62,1.067,1.067,0,0,1,.254-.606,1.469,1.469,0,0,0,.322-.671c.021-.109.035-.221.05-.332s.029-.228.052-.342a.373.373,0,0,1,.1-.184,1.168,1.168,0,0,0,.356-.587,4.983,4.983,0,0,0,.089-.534c.039-.329-.032-.462-.31-.543,0-.1-.006-.2-.008-.3a5.728,5.728,0,0,0-.053-.773,2.05,2.05,0,0,0-1.508-1.772,2.98,2.98,0,0,0-.861-.123,3.843,3.843,0,0,0-.542.04,2.1,2.1,0,0,0-1.816,1.5,3.782,3.782,0,0,0-.131,1.3l-.025.089a.587.587,0,0,0-.294.577,1.3,1.3,0,0,0,.349,1,.7.7,0,0,1,.227.524,1.92,1.92,0,0,0,.413,1.167,1.178,1.178,0,0,1,.2.386,2.039,2.039,0,0,1,.021.981.744.744,0,0,1-.5.556l-.612.266c-.441.191-.883.382-1.322.581a3.1,3.1,0,0,0-1,.62,2.291,2.291,0,0,0-.577,1.872.321.321,0,0,0,.292.3,2.1,2.1,0,0,0,.31.026h9.953a2.315,2.315,0,0,0,.259-.015.345.345,0,0,0,.358-.382,3.667,3.667,0,0,0-.011-.441Z" transform="translate(-449.977 -147.998)" [ngStyle]="rlad.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                    </svg>
  
                  </span>
                <h1 class="border" [ngStyle]="rlad ? {'background-color': btnColor} : {}"></h1> </a>
              <a routerLink="/chat-group"  routerLinkActive="active"  > 
                <!-- <img src="assets/icon_multiuser.svg" class="tab-img" />  -->
                <span class="span" routerLinkActive="active" #rlag="routerLinkActive"> 
                  
                  <svg class="tab-img" xmlns="http://www.w3.org/2000/svg" width="24.714" height="20.798" viewBox="0 0 24.714 20.798">
                    <g id="multiple-users-silhouette" transform="translate(0 -6.348)">
                      <path id="Path_1132" data-name="Path 1132" d="M14.914,9.918A4.491,4.491,0,0,1,17,13.254a3.623,3.623,0,1,0-2.09-3.336Zm-2.375,7.421a3.624,3.624,0,1,0-3.625-3.624A3.625,3.625,0,0,0,12.539,17.339Zm1.538.247H11A4.646,4.646,0,0,0,6.36,22.227v3.761l.01.059.259.081a21.125,21.125,0,0,0,6.31,1.018,12.954,12.954,0,0,0,5.51-1.034l.242-.122h.026V22.227A4.645,4.645,0,0,0,14.077,17.586Zm6-3.742H17.022a4.467,4.467,0,0,1-1.379,3.111,5.515,5.515,0,0,1,3.939,5.277v1.159a12.489,12.489,0,0,0,4.864-1.022l.242-.123h.026V18.485A4.646,4.646,0,0,0,20.074,13.844ZM6.179,13.6a3.6,3.6,0,0,0,1.928-.559,4.486,4.486,0,0,1,1.686-2.861c0-.068.01-.135.01-.2A3.624,3.624,0,1,0,6.179,13.6Zm3.255,3.358a4.47,4.47,0,0,1-1.378-3.094c-.113-.008-.225-.017-.341-.017H4.641A4.646,4.646,0,0,0,0,18.485v3.762l.01.058.259.082a21.759,21.759,0,0,0,5.226.982V22.233A5.516,5.516,0,0,1,9.435,16.956Z" [ngStyle]="rlag.isActive ? {'fill': btnColor} : {'fill': '#a6a6a6'}"/>
                    </g>
                  </svg>
  
                  </span>
                <h1 class="border" [ngStyle]="rlag ? {'background-color': btnColor} : {}"></h1> </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-3 col-xl-4 col-sm-3 col-3">
            <div class="third-sec">
              <div class="search-div">
                <input class="form-control search-input-nav" (change)="charCount($event.target.value)" value="{{filter}}" [(ngModel)]="filter"  placeholder="search"> <i
                  class="fa fa-search" ></i>
              </div>
              <div class="profile-div">
                <div class="dropdown">
                  <button class="dropdown dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img class="pro-icon" src={{avatar}} *ngIf="avatar"> 
                    <img class="pro-icon" src='assets/vuesax-bold-gallery.svg' *ngIf="!avatar"> 
                    <i class="fa-solid fa-angle-down down-icon"></i>
                  </button>
                  <ul style="z-index: 99999 !important;" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" routerLink="/dashboard/home/profile">Edit Profile</a></li>
                    <li><a class="dropdown-item" routerLink="/dashboard/notification">Notification Setting</a></li>
                    <li><a class="dropdown-item" routerLink="/dashboard/about">About </a></li>
                    <li><a class="dropdown-item" routerLink="/dashboard/terms">Terms of Service </a></li>
                    <li (click)="logout()"><a class="dropdown-item">Logout</a></li>
                  </ul>
                </div>
  
              </div>
            </div>
          </div>
        </div>
  
  
  
      </div>
    </div>
  
  </div> 
  
    <div class="container" *ngIf="!loading">
      <div class="row padding-record example">
        <div class="web cht">
          <button class="btn btn-primary cht-btn" (click)="openDialog('grpChat')">New Group Chat</button>
          <!-- <select name="" id="" *ngIf="memberList" (change)="oncreate($event.target)">
            <option value="" selected disabled>New Chat</option>
            <option value="{{memb | json}}" *ngFor="let memb of memberList" >
             {{memb?.name}}</option>
          </select> -->
        </div>
        <div>
          <div  [ngClass]="isMenuOpen ? 'displ' : 'dis'">
            <button class="btn cht-btn" [ngClass]="isMenuOpen ? 'displ' : 'dis'" (click)="openDialog('grpChat')">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          
        </div>
        <div id="root">
          <stream-channel-list (click)="isMenuOpen = false"
          class="channel-list menu-{{ isMenuOpen ? 'open' : 'close' }}"></stream-channel-list>
          <stream-channel>
            <stream-channel-header >
              <span  class="dropdown dropdown-toggle" style="position: absolute; right: 2em; cursor: pointer;" id="dropdownMenuButton2"
              data-bs-toggle="dropdown" aria-expanded="false">
              <span *ngIf="!load"><i class="fas fa-ellipsis-v" ></i></span></span>
              <ul style="z-index: 99999 !important;" class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li style="cursor: pointer;"><a class="dropdown-item" (click)="openDialog('pin')">Pinned Message</a></li>
              <li style="cursor: pointer;"><a class="dropdown-item" (click)="openDialog('grpMemb')">View Members</a></li>
                <!-- <li><a class="dropdown-item" routerLink="/dashboard/notification">Notification Setting</a></li>
                <li><a class="dropdown-item" routerLink="/dashboard/about">About </a></li>
                <li><a class="dropdown-item" routerLink="/dashboard/terms">Terms of Service </a></li>
                <li (click)="logout()"><a class="dropdown-item">Logout</a></li> -->
              </ul>


              
              <span class="menu-button" (click)="isMenuOpen = !isMenuOpen">
                <i class="fas fa-arrow-left" ></i>
              </span>  
              
            </stream-channel-header>
            <stream-message-list></stream-message-list>
            <stream-notification-list></stream-notification-list>
            <stream-message-input></stream-message-input>
            <stream-thread name="thread">
              <stream-message-list
                mode="thread"
              ></stream-message-list>
              <stream-message-input
                mode="thread"
              ></stream-message-input>
              <stream-autocomplete-textarea></stream-autocomplete-textarea>
            </stream-thread>
          </stream-channel>
        </div>
      </div>
    </div>
  
  
  