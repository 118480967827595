<router-outlet></router-outlet>

<!-- <div id="root">
    <stream-channel-list></stream-channel-list>
    <stream-channel>
      <stream-channel-header></stream-channel-header>
      <stream-message-list></stream-message-list>
      <stream-notification-list></stream-notification-list>
      <stream-message-input></stream-message-input>
      <stream-thread name="thread">
        <stream-message-list
          mode="thread"
        ></stream-message-list>
        <stream-message-input
          mode="thread"
        ></stream-message-input>
      </stream-thread>
    </stream-channel>
  </div>  -->
