import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
  HttpParams
} from '@angular/common/http';
import {
  AuthService
} from './auth.service';
import {
  Router
} from '@angular/router';
import {
  environment
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  baseUrl = environment.apiUrl;
  token: string;
  tokenXAuth : string = environment.tokenXAuth;
  userID: string;
  ItemId: number;
  changerouteId: number;
  playerdata: any;
  constructor(private http: HttpClient, private authService: AuthService, public router: Router) {
this.userID = localStorage.getItem('id')
   
    this.getAuthHeader();
   }

  // public getAuthHeader() {
  //   this.token = localStorage.getItem("authToken")
  //   let header: HttpHeaders;
  //   header = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     'Authorization': 'Bearer' + ' ' + this.token,
  //   });

  //   return header;
  // }

  public getAuthHeader() {
    this.token = localStorage.getItem("authToken")
    console.log(this.token)
    let header: HttpHeaders;
    header = new HttpHeaders({
      'X-Authorization': this.tokenXAuth,
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + ' ' + this.token,
    });

    return header;
  }
  public getAuthHeaderNoContent() {
    this.token = localStorage.getItem("authToken")
    console.log(this.token)
    let header: HttpHeaders;
    header = new HttpHeaders({
      'X-Authorization': this.tokenXAuth,
      'Authorization': 'Bearer' + ' ' + this.token,
    });

    return header;
  }

  public getQueryParam(searchText:any) {
   let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'query', searchText
    )
    return queryParams;
  }

  public createCommentHeader() {
    this.token = localStorage.getItem("authToken")
    console.log(this.token)
    let header: HttpHeaders;
    header = new HttpHeaders({
      'X-Authorization': this.tokenXAuth,
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer' + ' ' + this.token,
    });

    return header;
  }

  public authlogin() {
    let header: HttpHeaders;
    header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Authorization': this.tokenXAuth,
    
    });

    return header;
  }

  public forgotAuth() {
    let header: HttpHeaders;
    header = new HttpHeaders({
      'X-Authorization': this.tokenXAuth,
    
    });

    return header;
  }


  public login(data: any) {
    const body = {
      email_address: data.emailId,
      password: data.password
    };

    return this.http.post(this.baseUrl + '/signin', body);
  }


 

  


  public forgotPassword(data: any) {

    return this.http.post(this.baseUrl + '/users/send_password_reset_email', data, {
      headers: this.forgotAuth()
    });
  }



  public loginSetup(data: any) {
    const params = new HttpParams()
      .set('email', data.emailId)
      .set('password', data.password)
    return this.http.post( this.baseUrl + '/users/authenticate',  params,  {
      headers: this.authlogin()
    });
  }


  public feedList(page?, page_size?) {
    
    page = !page ? "" : page;
    page_size = !page_size ? 5 : page_size;
    return this.http.get(this.baseUrl +  `/updates?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeader()
    });
  }

   public aboutDetails() {
    return this.http.get(this.baseUrl +  '/about', {
      headers: this.getAuthHeader()
    });
  }
  public termsService() {
    return this.http.get(this.baseUrl +  '/about/tos', {
      headers: this.getAuthHeader()
    });
  }
  public needHelp() {
    return this.http.get(this.baseUrl +  '/about/help', {
      headers: this.getAuthHeader()
    });
  }

  public getProfile() {
    return this.http.get(this.baseUrl + '/get_user_profile',  {
      headers: this.getAuthHeader()
    });
  }


  public createLike(id: any) {
    return this.http.post(this.baseUrl +  '/updates/likes/' + id, '', {
      headers: this.getAuthHeader()
    });
  }

  public getFeedDetails(id: any) {
    return this.http.get(this.baseUrl +  '/updates/' + id, {
      headers: this.getAuthHeader()
    });
  }

  public getStoryDetails(id: any) {
    return this.http.get(this.baseUrl +  '/stories/' + id, {
      headers: this.getAuthHeader()
    });
  }

  public acceptStoryInvite(id: any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/invite/accept', {}, {
      headers: this.getAuthHeader()
    });
  }

  public deleteTextStoryContri(id: any, txtId:any) {
    return this.http.delete(this.baseUrl +  '/stories/' + id + '/contributions', {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public saveTextStoryContri(id: any, data:any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/contributions/text', data, {
      headers: this.getAuthHeader()
    });
  }

  public updateTextStoryContri(id: any, data:any, txtId:any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/contributions/text/' +txtId , data, {
      headers: this.getAuthHeader()
    });
  }

  public submitTextStoryContri(id: any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/contributions/submit', {}, {
      headers: this.getAuthHeader()
    });
  }

  public addPhotoStoryContri(id: any, data:any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/contributions/photos', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public deletePhotoStoryContri(id: any, txtId:any ) {
    return this.http.delete(this.baseUrl +  '/stories/' + id + '/contributions', {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public updatePhotoStoryContri(id: any, data:any, txtId:any) {
    return this.http.post(this.baseUrl +  '/stories/' + id + '/contributions/photos/' +txtId , data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public deleteSelectPhotoStoryContri(id: any, txtId:any ) {
    return this.http.delete(this.baseUrl +  '/stories/' + id + '/contributions/photos/' + txtId, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  

  public removeLike(id: any) {
    return this.http.delete(this.baseUrl +  '/updates/likes/' + id, {
      headers: this.getAuthHeader()
    });
  }
  public createComment(data: any, id: any) {

    return this.http.post(this.baseUrl + '/updates/comments/' + id , data, {
      headers: this.createCommentHeader()
    });
  }

  public getAllUser( page?, page_size?) {
    page = !page ? "" : page;
    page_size = !page_size ? 12 : page_size;
    return this.http.get(this.baseUrl +  `/users?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeader()
    });
  }

  public getSingleUser(id: any) {
    return this.http.get(this.baseUrl +  '/users/' + id, {
      headers: this.getAuthHeader()
    });
  }

  public getUserSetting() {
    return this.http.get(this.baseUrl +  '/users/settings', {
      headers: this.getAuthHeader()
    });
  }
  public saveUserSetting(data: any) {

    return this.http.post(this.baseUrl + '/users/settings', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public userLogout() {
    return this.http.post(this.baseUrl +  '/users/invalidate_token', '', {
      headers: this.getAuthHeader()
    });
  }

  public getUserProfile() {
    return this.http.get(this.baseUrl +  '/users/profile/edit', {
      headers: this.getAuthHeader()
    });
  }

  public getSearchData(searchText: any, page?, page_size?) {
    // debugger;
    page = !page ? "" : page;
    page_size = !page_size ? 5 : page_size;
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'query', searchText
    )
    return this.http.get(this.baseUrl +  `/updates?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeaderNoContent(), 
      params: queryParams
    });
  }
  public getContactSearchData(searchText: any, page?, page_size?) {
    page = !page ? "" : page;
    page_size = !page_size ? 10 : page_size;
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'query', searchText
    )
    return this.http.get(this.baseUrl +  `/users/search?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeaderNoContent(), 
      params: queryParams
    });
  }

  public updateAvtaar(data: any) {
    return this.http.post(this.baseUrl +  '/users/avatar', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public resetAvtaar(data: any) {
    const params = new HttpParams()
    .set('profile_image_reset', data)
    return this.http.post(this.baseUrl +  '/users/avatar', params, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public registerView(id: any) {
    return this.http.post(this.baseUrl +  '/updates/register_view/' + id, '', {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public getProfStatus() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'user_id', this.userID
    )
    return this.http.get(this.baseUrl +  '/users/status', {
      headers: this.getAuthHeaderNoContent(), 
      params: queryParams
    });
  }

  public updateStatus(data: any) {
    return this.http.post(this.baseUrl +  '/users/status/', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public getUserContribution(id: any) {
    return this.http.get(this.baseUrl +  '/voice_posts/' + id + '/contributions/' + this.userID, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public getContribution(id: any) {
    return this.http.get(this.baseUrl +  '/voice_posts/' + id + '/contributions/invitation_details', {
      headers: this.getAuthHeaderNoContent()
    });
  }
  public acceptContribution(id: any) {
    return this.http.post(this.baseUrl +  '/voice_posts/' + id + '/contributions/confirm',{}, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public createContribution(id: any, data) {
    return this.http.post(this.baseUrl +  '/voice_posts/' + id + '/contributions', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public confirmContribution(data: any, id:any) {
    // console.log(data)
    const body = {

    }
    return this.http.post(this.baseUrl +  '/voice_posts/' + id + '/contributions/' +this.userID+ '/submit', body, {
      headers: this.getAuthHeaderNoContent()
    });
  }
  public deleteContribution(id:any) {
    // console.log(data)
    const body = {

    }
    return this.http.delete(this.baseUrl +  '/voice_posts/' + id + '/contributions', {
      headers: this.getAuthHeaderNoContent()
    });
  }
  public getLikesList(id: any, page?, page_size?) {
    page = !page ? "" : page;
    page_size = !page_size ? 10 : page_size;
    return this.http.get(this.baseUrl +  `/updates/likes/${ id }?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeaderNoContent()
    });
  }
  public getCommentList(id: any, page?, page_size?) {
    page = !page ? "" : page;
    page_size = !page_size ? 10 : page_size;
    return this.http.get(this.baseUrl +  `/updates/comments/${ id }?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeaderNoContent()
    });
  }
  public getFeedbackCommentList(id: any, page?, page_size?) {
    page = !page ? "" : page;
    page_size = !page_size ? 10 : page_size;
    return this.http.get(this.baseUrl +  `/voice_posts/ ${id} /contributions/feedback/?page=${ page }&page_size=${ page_size }`, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public createFeedback(data: any, id: any) {

    return this.http.post(this.baseUrl + '/voice_posts/' + id + '/contributions/feedback', data, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public addModerator(adminId: any, userid: any) {
    const formData = new FormData();
      
    return this.http.post(this.baseUrl + '/chat/stream/channels/' + adminId + '/admin/moderators/' +userid, formData, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public removeModerator(adminId: any, userid: any) {
    const formData = new FormData();
      
    return this.http.delete(this.baseUrl + '/chat/stream/channels/' + adminId + '/admin/moderators/' +userid, {
      headers: this.getAuthHeaderNoContent()
    });
  }

  public groupImage(data: any, id:any) {
    console.log(data)
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'image', data
    )
    return this.http.post(this.baseUrl +  `/upload/chat/groups/${id}/image`, data, {
      headers: this.getAuthHeaderNoContent(),
    });
  }

}
