import { Component } from '@angular/core';
// import { StreamService } from 'src/app/stream.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OMGIVA';
  // constructor(private StreamService: StreamService) { }
}

// import { Component, OnInit } from '@angular/core';
// import { ChatClientService, ChannelService, StreamI18nService } from 'stream-chat-angular';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss'],
// })
// export class AppComponent implements OnInit {
//   constructor(
//     private chatService: ChatClientService,
//     private channelService: ChannelService,
//     private streamI18nService: StreamI18nService,
//   ) {
//     const apiKey = '9835gyj6ph75';
//     const userId = "0232dfa0-c239-11eb-9f90-79fbf746312c";
//     const userToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMDIzMmRmYTAtYzIzOS0xMWViLTlmOTAtNzlmYmY3NDYzMTJjIn0.0RzEcR-3z91gZkimzSMEytAIcFFWQ3OOYN1Q0kkOjNE";
//     this.chatService.init(apiKey, userId, userToken);
//     this.streamI18nService.setTranslation();
//   }

//   async ngOnInit() {
   
//     this.channelService.init({
//       type: 'messaging',
//       members: { $in: ['0232dfa0-c239-11eb-9f90-79fbf746312c']},
//     });
//   }
// }
